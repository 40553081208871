import React from "react";
import Link from "next/link";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from "swiper";
import { reachGoal } from "../../libs/helpers/metrics";
import { Button } from "react-bootstrap";

SwiperCore.use([Autoplay]);

export default function ExampleRequestsSlider() {
  const exampleRequestsList = [
    "get a salon-quality manicure in Los Angeles tonight",
    "enjoy fishing with a fisherman in Bergen, Norway",
    "have a picnic in Central Park with a photo session",
    "learn surfing with a coach for two tomorrow morning",
    "rent a tent and camping equipment at Burning Man",
    "find camera operator to create TIK TOK in Madeira",
    "take a look at campus of Google inside in Mountain View",
    "arrange a meeting with the BIG DATA developers in a bar",
    "hire shop assistant for the local market who knows how to haggle",
    "order hiking or biking drone shooting"
  ];

  return (
    <div className="d-block d-md-flex align-items-center">
      <div>
        <Link href="/">
          <Button onClick={() => reachGoal("Click_Landing_Create_Request")} variant="primary" className="mt-2">
            Create&nbsp;request
          </Button>
        </Link>
      </div>

      <span className="text-danger h1 ml-md-4 mb-0">&</span>

      <div className="ml-md-3">
        <Swiper
          allowTouchMove={false}
          loop
          autoHeight
          autoplay={{ delay: 3000 }}
          direction="horizontal"
          breakpoints={{
            768: {
              height: 100,
              direction: "vertical"
            }
          }}>
          {exampleRequestsList.map((request, index) => (
            <SwiperSlide key={index}>
              <h3 className="mb-0 w-100 text-md-left text-center">{request}</h3>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}
