import Link from "next/link";
import React, { ReactElement, useEffect } from "react";
import { Button, Image } from "react-bootstrap";
import Brand from "../../assets/brand.svg";
import canBecomeBuddyImg from "../../assets/landing/canBecomeBuddy.jpg";
import { reachGoal } from "../../libs/helpers/metrics";
import { ContentLayoutNarrow } from "../_Layout/ContentLayout";
import ExampleRequestsSlider from "./ExampleRequestsSlider";

let scrolledToEnd = false;
export default function LandingContents(): ReactElement {
  useEffect(() => {
    const scrollHandler = () => {
      if (scrolledToEnd) return null;

      const body = document.body,
        html = document.documentElement,
        height = Math.max(
          body.scrollHeight,
          body.offsetHeight,
          html.clientHeight,
          html.scrollHeight,
          html.offsetHeight
        );

      if ((window.pageYOffset || window.scrollY) + window.innerHeight >= height) {
        scrolledToEnd = true;
        reachGoal("Scroll_Landing_Section_FAQ", { isOnly: true });
      }
    };

    window.removeEventListener("scroll", scrollHandler);
    window.addEventListener("scroll", scrollHandler);

    return () => {
      window.removeEventListener("scroll", scrollHandler);
    };
  }, []);
  return (
    <>
      <section id="how-it-works">
        <div className="container">
          <div className="text-center mb-5">
            <h2>How it works</h2>
            <h4 className="font-italic text-muted">3 steps, just give it a try!</h4>
          </div>

          <ul className="timeline">
            <li>
              <div className="timeline-image">
                <Image
                  className="rounded-circle img-fluid"
                  src="https://cdn.pixabay.com/photo/2015/07/28/22/04/female-865110_1280.jpg"
                  alt=""
                />
              </div>
              <div className="timeline-panel">
                <div className="timeline-heading">
                  <h4>Leave your request</h4>
                </div>
                <div className="timeline-body">
                  <p className="text-muted">
                    Describe what you are looking for by creating a request on the platform. Specify when and where do
                    you need it and how much you are willing to pay.
                  </p>
                </div>
              </div>
            </li>
            <li className="timeline-inverted">
              <div className="timeline-image">
                <Image
                  className="rounded-circle img-fluid"
                  src="https://cdn.pixabay.com/photo/2015/08/05/01/12/phone-875488_1280.jpg"
                  alt=""
                />
              </div>
              <div className="timeline-panel">
                <div className="timeline-heading">
                  <h4>Talk with buddies</h4>
                </div>
                <div className="timeline-body">
                  <p className="text-muted">
                    Different local buddies will see and answer your request. You can discuss with them all details and
                    decide about final price.{" "}
                    <small className="text-muted">(Make sure you both understand the request.)</small>
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div className="timeline-image">
                <Image
                  className="rounded-circle img-fluid"
                  src="https://cdn.pixabay.com/photo/2016/11/08/05/10/students-1807505_1280.jpg"
                  alt=""
                />
              </div>
              <div className="timeline-panel">
                <div className="timeline-heading">
                  <h4>Choose your buddy</h4>
                </div>
                <div className="timeline-body">
                  <p className="text-muted">
                    Choose the buddy that better helps you.
                    <br className="d-none d-lg-block" /> And find the solution that best suits you.
                  </p>
                </div>
              </div>
            </li>
            <li className="timeline-inverted">
              <Link href="/" passHref>
                <a onClick={() => reachGoal("Click_Landing_Enjoy")}>
                  <div className="timeline-image bg-primary">
                    <h2>Try&nbsp;it!</h2>
                  </div>
                </a>
              </Link>
            </li>
          </ul>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="text-center mb-5">
            <h2 className="mb-4">Trust the locals</h2>
          </div>
        </div>
      </section>
      <section className="bg-light">
        <div className="container">
          <div className="text-center">
            <div className="mb-3">
              <Image style={{ width: 200 }} src={Brand} />
            </div>

            <ContentLayoutNarrow className="mx-auto">
              <ExampleRequestsSlider />
            </ContentLayoutNarrow>
          </div>
        </div>
      </section>
      <section className="showcase py-0">
        <div className="container-fluid p-0">
          <div className="row no-gutters">
            <div
              className="col-lg-6 order-lg-2 text-white showcase-img"
              style={{ backgroundImage: `url(${canBecomeBuddyImg})` }}></div>
            <div className="col-lg-6 order-lg-1 my-auto">
              <div className="showcase-text">
                <h2>What if I am traveller?</h2>
                <p className="lead mb-0">
                  You need to go through a simple registration, then describe your request. And simply choose with buddy
                  helps you best. A buddy is always in the city of your stay.
                </p>
                <Link href="/">
                  <Button onClick={() => reachGoal("Click_Landing_Enjoy")} variant="primary" className="mt-2">
                    Try it
                  </Button>
                </Link>
              </div>
            </div>
          </div>
          <div className="row no-gutters">
            <div
              className="col-lg-6 text-white showcase-img"
              style={{
                backgroundPositionY: "41%",
                backgroundImage:
                  "url(https://images.unsplash.com/photo-1542380536-3eb944a532d8?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=934&q=80)"
              }}></div>
            <div className="col-lg-6 my-auto">
              <div className="showcase-text">
                <h2>Can I become buddy?</h2>
                <p className="lead mb-0">
                  Yes! Join and make travellers trips more comfortable and enjoyable. Start making money on the
                  knowledge of the location where you reside.
                </p>
                <Link href="/">
                  <Button onClick={() => reachGoal("Click_Landing_Enjoy")} variant="primary" className="mt-2">
                    Join us
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
