import React, { DetailedHTMLProps, HTMLAttributes, ReactElement } from "react";
import { Col, ColProps } from "react-bootstrap";

interface Props extends ColProps {
  children: ReactElement | ReactElement[];
}

export function ContentLayoutUltraNarrow({
  children,
  style,
  ...props
}: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>): ReactElement {
  return (
    <div style={{ maxWidth: 450 }} {...props}>
      {children}
    </div>
  );
}

export function ContentLayoutNarrow({ children, ...props }: Props): ReactElement {
  return (
    <Col xs={12} sm={8} xl={6} {...props}>
      {children}
    </Col>
  );
}

export function ContentLayoutWide({ children, ...props }: Props): ReactElement {
  return (
    <Col xs={12} sm={10} lg={9} {...props}>
      {children}
    </Col>
  );
}
